html,body,#root {
  display:flex;
  flex:1;
  height:100%;
  flex-direction: column;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.browser-item {
  margin-top: 1rem;
}

.markdown {
  text-align: justify;
  margin:  100px auto;
  width: 60%;
}

.markdown h1, .markdown h2 {
 text-align: center;
}
.markdown h2:last-of-type{
  margin-bottom:50px;
}
.markdown p, .markdown h4, .markdown h5 {
  font-size: larger;
}
.markdown a {
  color: inherit;font-weight: bold;cursor: pointer; text-align: justify;
}
