.section {
  padding-left: 5rem;
  padding-right: 5rem;
}

@media only screen and (max-width: 600px) {
  .section {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
